import React from 'react';
import { IonImg } from '@ionic/react';

import './index.scss';

export interface ResumeImageProps {
  src: string;
  viewMode: string;
}

export default class ResumeImage extends React.Component<ResumeImageProps> {
  public render() {
    const Zoom = require('react-reveal/Zoom');
    if (this.props.viewMode === 'desktop') {
      return (
        <Zoom>
          <div className="resume-image">
            <IonImg src={this.props.src} />
          </div>
        </Zoom>
      );
    }
    return (
      <div className="resume-image">
        <IonImg src={this.props.src} />
      </div>
    );
  }
}
