import React from 'react';
import { IonSlides, IonSlide } from '@ionic/react';

//scss
import './index.scss';
import ViewHeader from '../view_header';

export interface Slide {
  title?: string;
  body: JSX.Element;
}

export interface SlideProps {
  slides: Slide[];
  loop: boolean;
  slidesPerView: number;
  speed?: number;
}

export default class Slides extends React.Component<SlideProps> {
  public static defaultProps = {
    loop: true,
    slidesPerView: 2,
  };

  public render() {
    const { slides } = this.props;

    const slideOpts = {
      initialSlide: 0,
      slidesPerView: this.props.slidesPerView ? this.props.slidesPerView : 2,
      speed: this.props.speed ? this.props.speed : 2500,
      slideShadows: true,
      autoplay: true,
      allowTouchMove: false,
      loop: true,
    };

    return (
      <IonSlides options={slideOpts}>
        {slides.map((slide, idx) => {
          if (slide.title) {
            return (
              <IonSlide key={idx}>
                <div className="slide-wrapper">
                  <ViewHeader>{slide.title}</ViewHeader>
                  <div className="slide-header">{slide.body}</div>
                </div>
              </IonSlide>
            );
          }
          return <IonSlide key={idx}>{slide.body}</IonSlide>;
        })}
      </IonSlides>
    );
  }
}
