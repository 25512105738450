import React from 'react';
import { ToolBarOptions, WindowMode } from '../App';
import ContactBar from '../components/contact-buttons/contact-bar-index';

import './tab_view.scss';

export interface TabViewProps {
  id: string;
  scrollable?: boolean;
  options: ToolBarOptions[];
  handleSelectDestination: (dest: string) => void;
  toggleContactVisible: () => void;
  viewMode: WindowMode;
}

export default class TabView extends React.Component<TabViewProps> {
  componentDidUpdate(prevProps: TabViewProps) {
    if (this.props.viewMode !== prevProps.viewMode) {
      this.render();
    }
  }

  public render() {
    const fullDisplay =
      this.props.viewMode === 'desktop' || this.props.viewMode === 'tablet';

    const triangleStyle =
      this.props.viewMode === 'desktop'
        ? 'tab-triangle'
        : this.props.viewMode === 'tablet'
        ? 'tab-triangle tab-triangle__tablet'
        : '';

    const triangleTopStyle =
      this.props.viewMode === 'desktop'
        ? 'tab-triangle-top'
        : this.props.viewMode === 'tablet'
        ? 'tab-triangle-top tab-triangle-top__tablet'
        : '';
    if (fullDisplay) {
      return (
        <div id="wrapper" className="tab-view__wrapper">
          <div className="tab-view">
            <div className={triangleStyle}></div>
            <div className={triangleTopStyle}>
              <div className="tab-links">
                <div className="tab-name">Ramell Collins</div>
                {this.renderLinks()}
              </div>
            </div>
            <ContactBar
              toggleContactVisible={this.props.toggleContactVisible}
            ></ContactBar>
            {this.props.children}
          </div>
        </div>
      );
    } else {
      return (
        <div className="tab-view__wrapper">
          <div className="tab-view">{this.props.children}</div>
        </div>
      );
    }
  }

  private renderLinks = () => {
    const links = this.props.options.map((op, idx) => {
      const linkClass =
        op.dest === this.props.id
          ? 'toolbar-options toolbar-options__selected'
          : 'toolbar-options';
      return (
        <div
          onClick={this.handleClickOption(op.dest)}
          className={linkClass}
          key={idx}
        >
          {op.text}
        </div>
      );
    });
    return <>{links}</>;
  };

  private handleClickOption = (dest: string) => {
    return () => {
      this.props.handleSelectDestination(dest);
    };
  };
}
