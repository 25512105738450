import React from 'react';

import './index.scss';

export interface ResumeTextProps {
  title?: string;
  subheader?: string;
  viewMode: string;
}

export default class ResumeText extends React.Component<ResumeTextProps> {
  public render() {
    const { title, subheader } = this.props;
    const Slide = require('react-reveal/Slide');
    if (this.props.viewMode === 'desktop') {
      return (
        <div className="resume-text">
          {title ? (
            <Slide left>
              <div className="resume-text__title">{title}</div>
            </Slide>
          ) : null}
          {subheader ? (
            <Slide right>
              <div className="resume-text__subheader">{subheader}</div>
            </Slide>
          ) : null}
          <Slide left>
            <div className="resume-text__body">{this.props.children}</div>
          </Slide>
        </div>
      );
    }
    return (
      <div className="resume-text">
        {title ? <div className="resume-text__title">{title}</div> : null}
        {subheader ? (
          <div className="resume-text__subheader">{subheader}</div>
        ) : null}
        <div className="resume-text__body">{this.props.children}</div>
      </div>
    );
  }
}
