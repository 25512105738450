import React from 'react';
import InstagramContact from './instagram-index';
import LinkedInContact from './linkedin-index';
import EmailContact from './mail-index';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuButton,
  IonMenuToggle,
  IonNote,
  IonTitle,
  IonToolbar,
} from '@ionic/react';

import './index.scss';

export interface ContactBarProps {
  toggleContactVisible: () => void;
  mobileView?: boolean;
}

export default class ContactBar extends React.Component<ContactBarProps> {
  public render() {
    if (this.props.mobileView) {
      return (
        <div className="contact-mobile">
          <InstagramContact></InstagramContact>
          <LinkedInContact></LinkedInContact>
          <EmailContact
            toggleContactVisible={this.props.toggleContactVisible}
          ></EmailContact>
        </div>
      );
    } else {
      return (
        <div className="contact">
          <InstagramContact></InstagramContact>
          <LinkedInContact></LinkedInContact>
          <EmailContact
            toggleContactVisible={this.props.toggleContactVisible}
          ></EmailContact>
        </div>
      );
    }
  }
}
