import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

export interface EmailContactProps {
  toggleContactVisible: () => void;
}

export default class EmailContact extends React.Component<EmailContactProps> {
  public render() {
    return (
      <a className="link">
        <FontAwesomeIcon
          icon={faEnvelope}
          className="icon"
          size="2x"
          onClick={this.props.toggleContactVisible}
        />
      </a>
    );
  }
}
